import moment from "moment"
import { IUserWithAccountsAndInvite } from "@clc-v2/utilities"
import classNames from "classnames"
import { IcEdit, IcInfo } from "@clc-v2/icons"

export const UserItem = (props: {
    user: IUserWithAccountsAndInvite,
    active: boolean,
    onEditHandler?: () => void;
    onInformationHandler?: () => void;
}) => {
    return (
        <div
            id={props.user.id}
            className={classNames(
                "w-full grid grid-cols-12 gap-1",
                "border-b border-white border-solid",
                "hover:bg-primary-dark-grey",
                {
                    "bg-secondary-grey": props.active
                }
            )}
        >
            <div className="overflow-auto" title={props.user.id}>
                <span className="text-white">{props.user.id}</span>
            </div>
            <div className="overflow-auto">
                <div className="text-white flex flex-col">
                    <span>{props.user.id_v1}</span>
                    <span className="break-all">{props.user.id_v1_firebase}</span>
                </div>
            </div>
            <div className="overflow-auto">
                <span className="text-white break-all">{props.user.email}</span>
            </div>
            <div className="overflow-auto">
                <span className="text-white break-all">{props.user.thumbnail_picture === '' ? props.user.picture : props.user.thumbnail_picture}</span>
            </div>
            <div className="overflow-auto">
                <span className="text-white">{props.user.role}</span>
            </div>
            <div className="overflow-auto flex flex-col">
                {props.user.accounts.map((account, id) => <span key={id} className="text-white break-all">{account.provider}: {account.provider_account_id}</span>)}
            </div>
            <div className="overflow-auto">
                <span className="text-white">{props.user.invited?.referer_id}</span>
            </div>
            <div className="overflow-auto">
                <span className="text-white">{props.user.last_login_at ? moment(props.user.last_login_at).format('YYYY-MM-DD HH:mm:ss') : 'Not set'}</span>
            </div>
            <div className="overflow-auto">
                <span className="text-white">{moment(props.user.created_at).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
            <div className="overflow-auto">
                <span className="text-white">{moment(props.user.updated_at).format('YYYY-MM-DD HH:mm:ss')}</span>
            </div>
            <div className="overflow-auto">
                <span className="text-white">{props.user.blocked_at ? moment(props.user.blocked_at).format('YYYY-MM-DD HH:mm:ss') : 'Active'}</span>
            </div>
            <div className="overflow-auto flex">
                <div className="cursor-pointer" onClick={props.onEditHandler}>
                    <IcEdit />
                </div>
                <div className="cursor-pointer" onClick={props.onInformationHandler}>
                    <IcInfo />
                </div>
            </div>
        </div>
    )
}