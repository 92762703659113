import { PlaqueTradeRule, PlaqueTradeHistory as TPlaqueTradeHistory } from "@prisma/client"
import { useEffect, useState } from "react";
import { PlaqueTradeRuleType, tradePlaqueService } from "../services/trade-plaque.service";
import { Button } from "@clc-v2/uis";
import Modal from "react-responsive-modal";
import moment from "moment";

export const PlaqueTradeHistory = () => {
  const [loading, set_loading] = useState<boolean>(false);
  const [trade_histories, set_trade_histories] = useState<TPlaqueTradeHistory[]>([]);
  const [err_msg, set_err_msg] = useState<string>('');
  const [loading_plaque_trade_rules, set_loading_plaque_trade_rules] = useState<boolean>(false);
  const [plaque_trade_rules, set_plaque_trade_rules] = useState<PlaqueTradeRule[]>([]);
  const [error_plaque_trade_rules, set_error_plaque_trade_rules] = useState<string>('');
  const [open_plaque_trade_rule_modal, set_open_plaque_trade_rule_modal] = useState<boolean>(false);
  const [edit_plaque_trade_rule, set_edit_plaque_trade_rule] = useState<PlaqueTradeRuleType>({
    id: '',
    plaque: 0,
    days: 0,
    text: '',
    auto: true,
  });
  const onCreateOrUpdateHandler = () => {
    tradePlaqueService.createOrUpdateTradePlaqueRule(edit_plaque_trade_rule).then((updated_trade_rule) => {
      if (edit_plaque_trade_rule.id) {
        set_plaque_trade_rules((_prev) => _prev.map((rule) => rule.id === edit_plaque_trade_rule.id ? updated_trade_rule : rule));
      } else {
        set_plaque_trade_rules((_prev) => [..._prev, updated_trade_rule]);
      }
    }).catch((err_msg: string) => {
      alert(err_msg);
    }).finally(() => {
      set_open_plaque_trade_rule_modal(false);
    });
  }
  const onDeleteHandler = (id: string) => {
    tradePlaqueService.deleteTradePlaqueRule(id).then(() => {
      set_plaque_trade_rules((_prev) => _prev.filter((rule) => rule.id !== id));
    }).catch((err_msg: string) => {
      alert(err_msg);
    });
  }
  const approveHandler = (trade_history_id: string) => {
    tradePlaqueService.approvePlaqueTradeRequest(trade_history_id).then((updated_history) => {
      set_trade_histories((_prev) => _prev.map((history) => history.id === updated_history.id ? updated_history : history));
    }).catch((err_msg: string) => {
      alert(err_msg);
    });
  }
  const rejectHandler = (trade_history_id: string) => {
    tradePlaqueService.rejectPlaqueTradeRequest(trade_history_id).then((updated_history) => {
      set_trade_histories((_prev) => _prev.map((history) => history.id === updated_history.id ? updated_history : history));
    }).catch((err_msg: string) => {
      alert(err_msg);
    });
  }
  useEffect(() => {
    set_loading(true);
    set_err_msg('');
    tradePlaqueService.getAllTradeHistory().then((histories) => {
      set_trade_histories(histories);
    }).catch((err_msg: string) => {
      set_err_msg(err_msg);
    }).finally(() => {
      set_loading(false);
    });
  }, []);
  useEffect(() => {
    set_loading_plaque_trade_rules(true);
    set_error_plaque_trade_rules('');
    tradePlaqueService.getPlaqueTradeRules().then((rules) => {
      set_plaque_trade_rules(rules);
    }).catch((err_msg: string) => {
      set_error_plaque_trade_rules(err_msg);
    }).finally(() => {
      set_loading_plaque_trade_rules(false);
    });
  }, []);
  return (
    <div className="">
      <div className="flex items-center gap-4">
        <label htmlFor="" className="text-white text-xl">Plaque Trade Rules</label>
        <Button className="px-4 py-2" type="secondary" onClick={() => set_open_plaque_trade_rule_modal(true)}>New</Button>
      </div>
      <div className="flex items-start gap-4 mt-4">
        <div className="flex flex-col gap-2 text-white">
          <div className="w-full grid grid-cols-9 gap-4">
            <span>Plaque</span>
            <span>Days</span>
            <span className="col-span-4">Display Text</span>
            <span>Auto Proceed?</span>
            <span></span>
            <span></span>
          </div>
          {plaque_trade_rules.map((rule) => (
            <div className="w-full grid grid-cols-9 gap-4" key={rule.id}>
              <span>{rule.plaque}</span>
              <span>{rule.days}</span>
              <span className="col-span-4">{rule.text}</span>
              <span>{rule.auto ? 'Auto' : 'Manual'}</span>
              <button className="text-whtie" onClick={() => {
                set_edit_plaque_trade_rule(rule);
                set_open_plaque_trade_rule_modal(true);
              }}>Edit</button>
              <button className="text-whtie" onClick={() => onDeleteHandler(rule.id)}>Delete</button>
            </div>
          ))}
        </div>
      </div>
      <Modal center showCloseIcon={false} classNames={{
        modal: "!bg-black w-96 border border-primary-golden"
      }} open={open_plaque_trade_rule_modal} onClose={() => set_open_plaque_trade_rule_modal(false)}>
        <div className="grid grid-cols-4 gap-2">
          <div className="col-span-4 text-white border-b pb-4 mb-4">
            <h4 className="text-xl">{edit_plaque_trade_rule.id !== '' ? 'Edit' : 'New'}</h4>
          </div>
          <div className="col-span-1 text-white">
            <label htmlFor="">Plaque</label>
          </div>
          <div className="col-span-3">
            <input className="w-full" type="text" value={edit_plaque_trade_rule.plaque} onChange={(e) => set_edit_plaque_trade_rule((_prev) => ({ ..._prev, plaque: parseInt(e.target.value) }))} />
          </div>
          <div className="col-span-1 text-white">
            <label htmlFor="">Days</label>
          </div>
          <div className="col-span-3">
            <input className="w-full" type="text" value={edit_plaque_trade_rule.days} onChange={(e) => set_edit_plaque_trade_rule((_prev) => ({ ..._prev, days: parseInt(e.target.value) }))} />
          </div>
          <div className="col-span-1 text-white">
            <label htmlFor="">Text</label>
          </div>
          <div className="col-span-3">
            <textarea className="w-full" rows={2} value={edit_plaque_trade_rule.text} onChange={(e) => set_edit_plaque_trade_rule((_prev) => ({ ..._prev, text: e.target.value }))} />
          </div>
          <div className="col-span-1 text-white">
            <label htmlFor="">Auto</label>
          </div>
          <div className="col-span-3">
            <input className="w-full" type="checkbox" checked={edit_plaque_trade_rule.auto} onChange={(e) => set_edit_plaque_trade_rule((_prev) => ({ ..._prev, auto: e.target.checked }))} />
          </div>
          <div className="col-span-4 flex justify-end">
            <Button type="primary" onClick={onCreateOrUpdateHandler}>{edit_plaque_trade_rule.id !== '' ? 'Update' : 'Create'}</Button>
          </div>
        </div>
      </Modal>
      <hr className="w-full border-white mt-4" />
      <div className="mt-4">
        <label htmlFor="" className="text-white text-xl">Plaque Trade History</label>
      </div>
      <div className="w-full grid grid-cols-10 gap-2 text-white">
        <div>
          History ID
        </div>
        <div>
          User ID
        </div>
        <div>
          Plaque Amount
        </div>
        <div>
          Trade Rule ID
        </div>
        <div>
          Requested At
        </div>
        <div>
          Approved At
        </div>
        <div>
          Completed At
        </div>
        <div>
          Rejected At
        </div>
        <div className="col-span-2">
          Action
        </div>
      </div>
      {
        trade_histories.map((trade_history) => (
          <div className="w-full grid grid-cols-10 gap-2 text-white">
            <div>
              {trade_history.id}
            </div>
            <div>
              {trade_history.user_id}
            </div>
            <div>
              {trade_history.plaque_amount}
            </div>
            <div>
              {trade_history.plaque_trade_rule_id}
            </div>
            <div>
              {moment(trade_history.requested_at).format('YYYY-MM-DD HH:mm')}
            </div>
            <div>
              {trade_history.approved_at ? moment(trade_history.approved_at).format('YYYY-MM-DD HH:mm') : ''}
            </div>
            <div>
              {trade_history.completed_at ? moment(trade_history.completed_at).format('YYYY-MM-DD HH:mm') : ''}
            </div>
            <div>
              {trade_history.rejected_at ? moment(trade_history.rejected_at).format('YYYY-MM-DD HH:mm') : ''}
            </div>
            <div>
              <button disabled={!!trade_history.completed_at} onClick={() => approveHandler(trade_history.id)}>{!trade_history.completed_at ? "Approve" : "Completed"}</button>
            </div>
            <div>
              <button disabled={!!trade_history.completed_at} onClick={() => rejectHandler(trade_history.id)}>{!trade_history.completed_at ? "Reject" : "Completed"}</button>
            </div>
          </div>
        ))
      }
    </div>
  )
}