import { Route, Routes, Outlet } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "./stores/hooks";
import { useEffect, useState } from "react";
import { ROLES, checkRole, parseJwt } from "libs/utilities/src";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import moment from "moment";
import axios from "axios";
import { accountService } from "./services/account.service";
import { set_user } from "./stores/auth";
import { Layout1 } from "./layouts/layout1";
import { TopicLessonQuestion } from "./pages/topic-lesson-question";
import { Library } from "./pages/library";
import { Users } from "./pages/users";
import { CBPayment } from "./pages/cb-payment";
import { Data } from "./pages/data";
import { ClcSpinner } from "@clc-v2/uis";
import { CustomerEarning } from "./pages/customer-earning";
import { PlayingHistory } from "./pages/playing-history";
import { ToastContainer } from 'react-toastify';
import { Reward } from "./pages/reward";
import { Report } from "./pages/report";
import { PlaqueTradeHistory } from "./pages/plaque-trade-history";

const EncryptionKey = process.env.NX_AUTH_ENCRYPTION_KEY ?? '';
const AuthCookieName = process.env.NX_AUTH_COOKIE_NAME ?? '';

const loginPath = process.env.NX_AUTH_DOMAIN ?? '' + '/auth/login';
export const loginUrl = `${loginPath}?redirect=${encodeURIComponent(window.location.href)}`;

const PrivateRoute = ({ role }: { role: number }) => {
    const user = useAppSelector((state) => state.auth.user);
    if (
        user?.role && checkRole(role, user?.role)
    ) {
        return <Outlet />
    } else {
        window.location.href = loginUrl;
    }
}

export const AppRoutes = () => {
    const [loadingCookie, setLoadingCookie] = useState<boolean>(true);
    const dispatch = useAppDispatch();
    useEffect(() => {
        // console.log(process.env)
        const cookieAuthData = Cookies.get(AuthCookieName);
        if (cookieAuthData) {
            const bytes = CryptoJS.AES.decrypt(cookieAuthData, EncryptionKey);
            const accessToken = bytes.toString(CryptoJS.enc.Utf8);
            const { user_id, exp, iat, last_login_at } = parseJwt(accessToken);
            if (moment.unix(exp).diff(moment(), 'seconds') > 0) {
                // token is valid
                axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
                accountService.selfAccount(user_id).then((_user) => {
                    if (checkRole(ROLES.MANAGE_PAGE_VIEW, _user.role)) {
                        dispatch(
                            set_user(_user)
                        )
                    } else {
                        alert('You are not permitted');
                        window.location.href = loginUrl;
                    }
                }).catch((err_msg: string) => {
                    setTimeout(() => {
                        window.location.href = loginUrl;
                    }, 500);
                }).finally(() => {
                    setTimeout(() => {
                        setLoadingCookie(false);
                    }, 500);
                });
            } else {
                // token is expired
                setTimeout(() => {
                    window.location.href = loginUrl;
                }, 500);
            }
        } else {
            setTimeout(() => {
                window.location.href = loginUrl;
            }, 500);
        }
    }, []);
    if (loadingCookie) {
        return (
            <div className="bg-primary-carbon w-scree h-screen flex justify-center items-center">
                <ClcSpinner text="Loading" />
            </div>
        )
    } else {
        return (
            <>
                <Routes>
                    <Route path="/" element={<Layout1 />}>
                        <Route path="topic-lesson-question" element={<TopicLessonQuestion />} />
                        <Route path="library" element={<Library />} />
                        <Route path="users" element={<Users />} />
                        <Route path="playing-history" element={<PlayingHistory />} />
                        <Route path="customer-earning" element={<CustomerEarning />} />
                        <Route path="cb-payment" element={<CBPayment />} />
                        <Route path="data" element={<Data />} />
                        <Route path="reward" element={<Reward />} />
                        <Route path="report" element={<Report />} />
                        <Route path="plaque" element={<PlaqueTradeHistory />} />
                        {/* <Route path="*" element={<TopicLessonQuestion />} /> */}
                    </Route>
                </Routes>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </>
        )
    }
}