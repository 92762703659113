import { PlaqueTradeHistory, PlaqueTradeRule } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from "../config";
import axios, { type AxiosError } from "axios";

export type PlaqueTradeRuleType = Pick<PlaqueTradeRule, 'id' | 'plaque' | 'days' | 'text' | 'auto'>;

class TradePlaqueService {
  getPlaqueTradeRules() {
    return new Promise<PlaqueTradeRule[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/trade-plaque/rules`).then((res: { data: PlaqueTradeRule[] }) => {
        resolve(res.data)
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  getAllTradeHistory() {
    return new Promise<PlaqueTradeHistory[]>((resolve, reject) => {
      axios.get(`${API_BACKEND_ENDPOINT}/trade-plaque/trade-history-all`).then((res: { data: PlaqueTradeHistory[] }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    })
  }
  createOrUpdateTradePlaqueRule(trade_rule: PlaqueTradeRuleType) {
    return new Promise<PlaqueTradeRule>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/trade-plaque/trade-rule`, trade_rule).then((res: { data: PlaqueTradeRule }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  deleteTradePlaqueRule(id: string) {
    return new Promise<PlaqueTradeRule>((resolve, reject) => {
      axios.delete(`${API_BACKEND_ENDPOINT}/trade-plaque/trade-rule?id=${id}`).then((res: { data: PlaqueTradeRule }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  approvePlaqueTradeRequest(id: string) {
    return new Promise<PlaqueTradeHistory>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/trade-plaque/approve-trade-request`, {
        id
      }).then((res: { data: PlaqueTradeHistory }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
  rejectPlaqueTradeRequest(id: string) {
    return new Promise<PlaqueTradeHistory>((resolve, reject) => {
      axios.post(`${API_BACKEND_ENDPOINT}/trade-plaque/reject-trade-request`, {
        id
      }).then((res: { data: PlaqueTradeHistory }) => {
        resolve(res.data);
      }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
        if (err.response) {
          reject(err.response.data.message);
        } else {
          reject(err.message);
        }
      });
    });
  }
}

export const tradePlaqueService = new TradePlaqueService();
