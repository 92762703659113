import { Question } from "@prisma/client"
import { ChangeEvent, useEffect, useState } from "react";
import { topicLessonQuestionService } from "../../services/topic-lesson-question.service";
import lodash from "lodash";
import Modal from "react-responsive-modal";
import { useAppSelector } from "../../stores/hooks";
import { toast } from "react-toastify";
import moment from "moment";

export const QuestionsPanel = () => {
  const { lessons } = useAppSelector((state) => state.topicLessonQuestion);
  const [questions, set_questions] = useState<Question[]>([]);
  const [loading_questions, set_loading_questions] = useState<boolean>(false);
  const [page, set_page] = useState<number>(1);
  const [hand_number, set_hand_number] = useState<number>(0);
  const [open_new_question, set_open_new_question] = useState<boolean>(false);
  const [new_question, set_new_question] = useState<Question>({
    hand_number: 0,
    id_v1: '',
    answer_correct: '',
    answer_wrong: [],
    explanation_correct: '',
    explanation_wrong: '',
    hand_history: '',
    lesson_uid: '',
    new_handed_at: '',
    question: '',
    reward_chip: 0,
    reward_chip_wrong: [],
    reward_ticket: 0,
    additional_chip: 0,
    subtract_chip: 0,
    video_explanation: '',
    video_link: '',
    blocked_at: null,
  });
  const [open_edit_question, set_open_edit_question] = useState<boolean>(false);
  const [edit_question, set_edit_question] = useState<Question>({
    hand_number: 0,
    id_v1: '',
    answer_correct: '',
    answer_wrong: [],
    explanation_correct: '',
    explanation_wrong: '',
    hand_history: '',
    lesson_uid: '',
    new_handed_at: '',
    question: '',
    reward_chip: 0,
    reward_chip_wrong: [],
    reward_ticket: 0,
    additional_chip: 0,
    subtract_chip: 0,
    video_explanation: '',
    video_link: '',
    blocked_at: null,
  });
  const get_all_questions_by_page = () => {
    topicLessonQuestionService.getAllQuestionsByPage(page, hand_number).then((_questions) => {
      set_questions(_questions);
    }).catch((err_msg: string) => {
      console.error(err_msg);
    });
  }
  const update_edit_question = () => {
    set_loading_questions(true);
    topicLessonQuestionService.updateEditQuestion({
      hand_number: edit_question.hand_number,
      question: edit_question.question,
      hand_history: edit_question.hand_history,
      answer_correct: edit_question.answer_correct,
      answer_wrong: edit_question.answer_wrong,
      explanation_correct: edit_question.explanation_correct,
      explanation_wrong: edit_question.explanation_wrong,
      reward_chip: edit_question.reward_chip,
      reward_chip_wrong: edit_question.reward_chip_wrong,
      reward_ticket: edit_question.reward_ticket,
      additional_chip: edit_question.additional_chip,
      subtract_chip: edit_question.subtract_chip,
      new_handed_at: edit_question.new_handed_at,
      lesson_uid: edit_question.lesson_uid,
      video_link: edit_question.video_link,
      video_explanation: edit_question.video_explanation,
      id_v1: null,
      blocked_at: edit_question.blocked_at,
    }).then((_question) => {
      set_questions((_prev_questions) => _prev_questions.map((_prev_question) => _prev_question.hand_number === _question.hand_number ? _question : _prev_question));
      toast.success(`Question (${_question.hand_number}) is updated.`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_questions(false);
    })
  }
  const register_new_question = () => {
    set_loading_questions(true);
    topicLessonQuestionService.registerNewQuestion({
      hand_number: new_question.hand_number,
      question: new_question.question,
      hand_history: new_question.hand_history,
      answer_correct: new_question.answer_correct,
      answer_wrong: new_question.answer_wrong,
      explanation_correct: new_question.explanation_correct,
      explanation_wrong: new_question.explanation_wrong,
      reward_chip: new_question.reward_chip,
      reward_chip_wrong: new_question.reward_chip_wrong,
      reward_ticket: new_question.reward_ticket,
      additional_chip: new_question.additional_chip,
      subtract_chip: new_question.subtract_chip,
      new_handed_at: new_question.new_handed_at,
      lesson_uid: new_question.lesson_uid,
      video_link: new_question.video_link,
      video_explanation: new_question.video_explanation,
      id_v1: null,
      blocked_at: null,
    }).then((_question) => {
      set_questions((_prev_questions) => [..._prev_questions, _question]);
      toast.warn(`Question ${_question.hand_number} is registered`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).catch((err_msg: string) => {
      toast.warn(err_msg, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }).finally(() => {
      set_loading_questions(false);
    })
  }
  const delete_question = (hand_number: number) => {
    const yes = confirm(`Do you want to delete lesson ${hand_number}?`);
    if (yes) {
      topicLessonQuestionService.removeQuestion(hand_number).then((_removed_question) => {
        set_questions((_prev) => _prev.filter((question) => question.hand_number !== _removed_question.hand_number));
        toast.success(`Question (${_removed_question.hand_number}) is deleted`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      });
    }
  }
  const questions_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_questions(true);
      topicLessonQuestionService.uploadQuestionsCSV(file).then((success) => {
        // get_all_questions_of_active_lesson(active_lesson_id, lessons);
        toast.warn(`Question csv file is uploaded  successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_questions(false);
      });
    }
  }
  const questions_backup_v1_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file: File = e.target.files[0];
      set_loading_questions(true);
      topicLessonQuestionService.uploadQuestionsBackupV1CSV(file).then((success) => {
        // get_all_questions_of_active_lesson(active_lesson_id, lessons);
        toast.warn(`Question csv file(v1) is uploaded successfully`, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).catch((err_msg: string) => {
        toast.warn(err_msg, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }).finally(() => {
        set_loading_questions(false);
      });
    }
  }
  useEffect(() => {
    get_all_questions_by_page();
  }, [hand_number, page]);
  return (
    <div className="w-full flex flex-col">
      <div className="flex justify-between items-center gap-6 px-4 py-2 text-white mt-4">
        <div className="flex items-center gap-4">
          <input className="text-black" type="number" placeholder="Hand Number" onChange={lodash.debounce((e) => {
            set_hand_number(parseInt(e.target.value));
          }, 500)} />
          <label htmlFor="page">Page:</label>
          <input id="page" className="w-16 text-black" type="number" defaultValue={1} min={1} max={1000} onChange={lodash.debounce((e) => {
            set_page(parseInt(e.target.value));
          }, 500)} />
        </div>
        <div className="flex items-center gap-6">
          <button className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1" onClick={() => set_open_new_question(true)}>Register</button>
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-csv-file-upload"
            title="Upload CSV Data from google sheet"
          >
            Upload CSV
          </label>
          <input id="topics-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={questions_csv_upload_handler} />
          <label
            className="rounded-full flex justify-center items-center cursor-pointer border px-3 py-1"
            htmlFor="topics-backup-v1-csv-file-upload"
            title="Upload CSV Data from v1 db backup"
          >
            Upload CSV V1
          </label>
          <input id="topics-backup-v1-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={questions_backup_v1_csv_upload_handler} />
        </div>
      </div>
      {questions.map((question, id) => (
        <div className="w-full flex items-start gap-4 text-white border-b py-4 px-4" key={id}>
          <div className="w-10 grow-0 shrink-0">
            {question.hand_number}
          </div>
          <div className="w-20 grow-0 shrink-0">
            {question.lesson_uid}
          </div>
          <div className="shrink grow" dangerouslySetInnerHTML={{ __html: question.question }} />
          <div className="w-36 grow-0 shrink-0">
            {question.new_handed_at}
          </div>
          <div className="w-36 grow-0 shrink-0">
            {question.answer_correct}
          </div>
          <div className="w-20 grow-0 shrink-0">
            {question.reward_chip} | {question.reward_ticket} | {question.additional_chip} |  {question.subtract_chip}
          </div>
          <div className="w-32 flex items-center gap-6 grow-0 shrink-0">
            <button
              onClick={() => {
                set_edit_question({
                  hand_number: question.hand_number,
                  id_v1: question.id_v1,
                  answer_correct: question.answer_correct,
                  answer_wrong: question.answer_wrong,
                  explanation_correct: question.explanation_correct,
                  explanation_wrong: question.explanation_wrong,
                  hand_history: question.hand_history,
                  lesson_uid: question.lesson_uid,
                  new_handed_at: question.new_handed_at,
                  question: question.question,
                  reward_chip: question.reward_chip,
                  reward_chip_wrong: question.reward_chip_wrong,
                  reward_ticket: question.reward_ticket,
                  additional_chip: question.additional_chip,
                  subtract_chip: question.subtract_chip,
                  video_explanation: question.video_explanation,
                  video_link: question.video_link,
                  blocked_at: question.blocked_at,
                });
                set_open_edit_question(true);
              }}
            >Edit</button>
            <button
              onClick={() => {
                delete_question(question.hand_number);
              }}
            >Delete</button>
          </div>
        </div>
      ))}
      <Modal open={open_new_question} onClose={() => set_open_new_question(false)}>
        <h2>Register New Question</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Lesson ID" title="Required lesson id of question">Question's Lesson ID(*)</label>
          <select className="grow shrink border p-2" value={new_question.lesson_uid} onChange={(e) => set_new_question((_prev) => ({ ..._prev, lesson_uid: e.target.value }))}>
            <option value="un-selected">Select Your Lesson</option>
            {(lessons ?? []).map((lesson, id) => <option key={id} value={lesson.uid} selected={new_question.lesson_uid === lesson.uid}>{lesson.uid}: {lesson.name}</option>)}
          </select>
          {/* <input className="grow shrink border p-2" type="text" id="Edit Lesson Topic ID" value={new_lesson.topic_uid} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, topic_uid: e.target.value }))} /> */}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Hand Number" title="Required HandNumber of question">Question HandNumber(*)</label>
          <input className="grow shrink border p-2" type="number" id="New Question Hand Number" value={new_question.hand_number} onChange={(e) => set_new_question((_prev) => ({ ..._prev, hand_number: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Question" title="Required text of question">Question Text(*)</label>
          <textarea className="grow shrink border p-2" id="New Question Question" value={new_question.question} onChange={(e) => set_new_question((_prev) => ({ ..._prev, question: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Hand History" title="Required hand history of question">Question Hand History(*)</label>
          <textarea rows={5} className="grow shrink border p-2" id="New Question Hand History" value={new_question.hand_history} onChange={(e) => set_new_question((_prev) => ({ ..._prev, hand_history: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 grow-0 shrink-0 text-black" htmlFor="New Question Answer Correct" title="Required Correct Answer of question">Correct Answer(*)</label>
          <div className="grid grid-cols-3 gap-2">
            <input className="w-full border p-2" type="text" id="New Question Answer Correct" placeholder="Correct Answer" value={new_question.answer_correct} onChange={(e) => set_new_question((_prev) => ({ ..._prev, answer_correct: e.target.value }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="New Question Reward Chip" title="Required chip reward of question">Reward Chip(*)</label>
            </div>
            <input className="w-full border p-2" type="number" id="New Question Reward Chip" value={new_question.reward_chip} onChange={(e) => set_new_question((_prev) => ({ ..._prev, reward_chip: parseInt(e.target.value) }))} />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="New Question Answer Wrong" title="Required Wrong Answers of question">Wrong Answer 1(*)</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong 1" placeholder="Wrong 1" value={new_question.answer_wrong[0]} onChange={(e) => set_new_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 0 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="New Question Reward Chip Wrong 1" title="Required chip reward of question(wrong 1)">Reward Chip Wrong 1(*)</label>
            </div>
            <input className="w-full border p-2" type="number" id="New Question Reward Chip Wrong 1" value={new_question.reward_chip_wrong[0] ?? ''} onChange={(e) => set_new_question((_prev) => ({ ..._prev, reward_chip_wrong: [parseInt(e.target.value), ...new_question.reward_chip_wrong.slice(1)] }))} />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="New Question Answer Wrong 2" title="Required Wrong Answers of question">Wrong Answer 2</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong 2" placeholder="Wrong 3" value={new_question.answer_wrong[1]} onChange={(e) => set_new_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 1 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="New Question Reward Chip Wrong 2" title="Required chip reward of question(wrong 2)">Reward Chip Wrong 2</label>
            </div>
            <input className="w-full border p-2" type="number" id="New Question Reward Chip Wrong 1" value={new_question.reward_chip_wrong[1] ?? ''} onChange={(e) => set_new_question((_prev) => ({ ..._prev, reward_chip_wrong: [new_question.reward_chip_wrong[0], parseInt(e.target.value), ...new_question.reward_chip_wrong.slice(2)] }))} />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="New Question Answer Wrong 3" title="Required Wrong Answers of question">Wrong Answer 3</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong 3" placeholder="Wrong 3" value={new_question.answer_wrong[2]} onChange={(e) => set_new_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 2 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="New Question Reward Chip Wrong 3" title="Required chip reward of question(wrong 3)">Reward Chip Wrong 3</label>
            </div>
            <input className="w-full border p-2" type="number" id="New Question Reward Chip Wrong 3" value={new_question.reward_chip_wrong[2] ?? ''} onChange={(e) => set_new_question((_prev) => ({ ..._prev, reward_chip_wrong: [...new_question.reward_chip_wrong.slice(0, 2), parseInt(e.target.value), ...new_question.reward_chip_wrong.slice(3)] }))} />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="New Question Answer Wrong 4" title="Required Wrong Answers of question">Wrong Answer 4</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong 4" placeholder="Wrong 4" value={new_question.answer_wrong[3]} onChange={(e) => set_new_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 2 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="New Question Reward Chip Wrong 4" title="Required chip reward of question(wrong 4)">Reward Chip Wrong 4</label>
            </div>
            <input className="w-full border p-2" type="number" id="New Question Reward Chip Wrong 4" value={new_question.reward_chip_wrong[3] ?? ''} onChange={(e) => set_new_question((_prev) => ({ ..._prev, reward_chip_wrong: [...new_question.reward_chip_wrong.slice(0, 3), parseInt(e.target.value)] }))} />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Explanation Correct" title="Required correct explanation of question">Correct Explanation(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="New Question Explanation Correct" value={new_question.explanation_correct} onChange={(e) => set_new_question((_prev) => ({ ..._prev, explanation_correct: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Explanation Wrong" title="Required wrong explanation of question">Wrong Explanation(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="New Question Explanation Wrong" value={new_question.explanation_wrong} onChange={(e) => set_new_question((_prev) => ({ ..._prev, explanation_wrong: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Video Link" title="Required video link of question">Video Link(Optional)</label>
          <textarea rows={3} className="grow shrink border p-2" id="New Question Video Link" value={new_question.video_link ?? ''} onChange={(e) => set_new_question((_prev) => ({ ..._prev, video_link: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Video Explanation" title="Required video explanation of question">Video Explanation(Optional)</label>
          <textarea rows={3} className="grow shrink border p-2" id="New Question Video Explanation" value={new_question.video_explanation ?? ''} onChange={(e) => set_new_question((_prev) => ({ ..._prev, video_explanation: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Reward Tickets" title="Required tickets reward of question">Reward Tickets(*)</label>
          <input className="grow shrink border p-2" type="number" id="New Question Reward Tickets" value={new_question.reward_ticket} onChange={(e) => set_new_question((_prev) => ({ ..._prev, reward_ticket: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Additional Chip" title="Required additional chip of question">Additional Chip(AI)</label>
          <input className="grow shrink border p-2" type="number" id="New Question Additional Chip" value={new_question.additional_chip} onChange={(e) => set_new_question((_prev) => ({ ..._prev, additional_chip: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Subtract Chip" title="Required subtract chip of question">Subtract Chip(AI)</label>
          <input className="grow shrink border p-2" type="number" id="New Question Subtract Chip" value={new_question.subtract_chip} onChange={(e) => set_new_question((_prev) => ({ ..._prev, subtract_chip: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question New Handed At" title="Required chip reward of question">New Handed At</label>
          <input className="grow shrink border p-2" type="text" id="New Question New Handed At" value={new_question.new_handed_at ?? ""} onChange={(e) => set_new_question((_prev) => ({ ..._prev, new_handed_at: e.target.value }))} placeholder="YYYY-MM-DD" />
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_new_question(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_questions} onClick={register_new_question}>{loading_questions ? 'Registering' : 'Register'}</button>
        </div>
      </Modal>
      <Modal open={open_edit_question} onClose={() => set_open_edit_question(false)}>
        <h2>Update Question {edit_question.hand_number}</h2>
        <div className="py-2">
          <hr />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Lesson ID" title="Required lesson id of question">Question's Lesson ID(*)</label>
          <select className="grow shrink border p-2" value={edit_question.lesson_uid} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, lesson_uid: e.target.value }))}>
            <option value="un-selected">Select Your Lesson</option>
            {(lessons ?? []).map((lesson, id) => <option key={id} value={lesson.uid} selected={edit_question.lesson_uid === lesson.uid}>{lesson.uid}: {lesson.name}</option>)}
          </select>
          {/* <input className="grow shrink border p-2" type="text" id="Edit Lesson Topic ID" value={new_lesson.topic_uid} onChange={(e) => set_new_lesson((_prev) => ({ ..._prev, topic_uid: e.target.value }))} /> */}
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Question" title="Required text of question">Question Text(*)</label>
          <textarea className="grow shrink border p-2" id="Edit Question Question" value={edit_question.question} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, question: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Hand History" title="Required hand history of question">Question Hand History(*)</label>
          <textarea rows={5} className="grow shrink border p-2" id="Edit Question Hand History" value={edit_question.hand_history} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, hand_history: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 grow-0 shrink-0 text-black" htmlFor="Edit Question Answer Correct" title="Required Correct Answer of question">Correct Answer(*)</label>
          <div className="grow shrink grid grid-cols-3 gap-2">
            <input className="w-full border p-2" type="text" id="Edit Question Answer Correct" value={edit_question.answer_correct} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, answer_correct: e.target.value }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="Edit Question Reward Chip" title="Required chip reward of question">Reward Chip(*)</label>
            </div>
            <input className="w-full border p-2" type="number" id="Edit Question Reward Chip" value={edit_question.reward_chip} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, reward_chip: parseInt(e.target.value) }))} placeholder="Reward Chip" />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="Edit Question Answer Wrong" title="Required Wrong Answers of question">Wrong Answer 1(*)</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong" placeholder="Wrong 1" value={edit_question.answer_wrong[0]} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 0 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="Edit Question Reward Chip(Wrong 1)" title="Required chip reward of question (wrong 1)">Reward Chip(*)</label>
            </div>
            <input className="w-full border p-2" type="number" id="Edit Question Reward Chip(Wrong 1)" value={edit_question.reward_chip_wrong[0] ?? 0} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, reward_chip_wrong: [parseInt(e.target.value), ..._prev.reward_chip_wrong.slice(1)] }))} placeholder="Reward Chip for Wrong 1" />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="Edit Question Answer Wrong" title="Required Wrong Answers of question">Wrong Answer 2</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong" placeholder="Wrong 2" value={edit_question.answer_wrong[1]} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 1 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="Edit Question Reward Chip(Wrong 2)" title="Required chip reward of question (wrong 2)">Reward Chip</label>
            </div>
            <input className="w-full border p-2" type="number" id="Edit Question Reward Chip(Wrong 2)" value={edit_question.reward_chip_wrong[1] ?? 0} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, reward_chip_wrong: [_prev.reward_chip_wrong[0], parseInt(e.target.value), ..._prev.reward_chip_wrong.slice(2)] }))} placeholder="Reward Chip for Wrong 2" />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="Edit Question Answer Wrong" title="Required Wrong Answers of question">Wrong Answer 3</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong" placeholder="Wrong 3" value={edit_question.answer_wrong[2]} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 2 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="Edit Question Reward Chip(Wrong 3)" title="Required chip reward of question (wrong 3)">Reward Chip</label>
            </div>
            <input className="w-full border p-2" type="number" id="Edit Question Reward Chip(Wrong 3)" value={edit_question.reward_chip_wrong[2] ?? 0} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, reward_chip_wrong: [..._prev.reward_chip_wrong.slice(0, 2), parseInt(e.target.value), ..._prev.reward_chip_wrong.slice(3)] }))} placeholder="Reward Chip for Wrong 3" />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 shrink-0 grow-0 text-black" htmlFor="Edit Question Answer Wrong" title="Required Wrong Answers of question">Wrong Answer 4</label>
          <div className="shrink grow grid grid-cols-3 gap-2">
            <input className="border p-2" type="text" id="New Question Answer Wrong" placeholder="Wrong 4" value={edit_question.answer_wrong[3]} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, answer_wrong: _prev.answer_wrong.map((answer, id) => id === 3 ? e.target.value : answer) }))} />
            <div className="flex items-center">
              <label className="text-black" htmlFor="Edit Question Reward Chip(Wrong 4)" title="Required chip reward of question (wrong 4)">Reward Chip</label>
            </div>
            <input className="w-full border p-2" type="number" id="Edit Question Reward Chip(Wrong 4)" value={edit_question.reward_chip_wrong[3] ?? 0} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, reward_chip_wrong: [..._prev.reward_chip_wrong.slice(0, 3), parseInt(e.target.value)] }))} placeholder="Reward Chip for Wrong 4" />
          </div>
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Explanation Correct" title="Required correct explanation of question">Correct Explanation(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Edit Question Explanation Correct" value={edit_question.explanation_correct} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, explanation_correct: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Explanation Wrong" title="Required wrong explanation of question">Wrong Explanation(*)</label>
          <textarea rows={3} className="grow shrink border p-2" id="Edit Question Explanation Wrong" value={edit_question.explanation_wrong} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, explanation_wrong: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Video Link" title="Required video link of question">Video Link(Optional)</label>
          <textarea rows={3} className="grow shrink border p-2" id="New Question Video Link" value={edit_question.video_link ?? ''} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, video_link: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="New Question Video Explanation" title="Required video explanation of question">Video Explanation(Optional)</label>
          <textarea rows={3} className="grow shrink border p-2" id="New Question Video Explanation" value={edit_question.video_explanation ?? ''} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, video_explanation: e.target.value }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Reward Tickets" title="Required chip reward of question">Reward Tickets(*)</label>
          <input className="grow shrink border p-2" type="number" id="Edit Question Reward Tickets" value={edit_question.reward_ticket} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, reward_ticket: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Additional Chip" title="Required additional chip of question">Additional Chip(AI)</label>
          <input className="grow shrink border p-2" type="number" id="Edit Question Additional Chip" value={edit_question.additional_chip} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, additional_chip: parseInt(e.target.value) }))} />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question Reward Tickets" title="Required chip reward of question">Subtract Chip(AI)</label>
          <input className="grow shrink border p-2" type="number" id="Edit Question Reward Tickets" value={edit_question.subtract_chip} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, subtract_chip: parseInt(e.target.value) }))} />
        </div >
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" htmlFor="Edit Question New Handed At" title="Required chip reward of question">New Handed At</label>
          <input className="grow shrink border p-2" type="text" id="Edit Question New Handed At" value={edit_question.new_handed_at ?? ""} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, new_handed_at: e.target.value }))} placeholder="YYYY-MM-DD" />
        </div>
        <div className="flex items-center gap-2 mt-2">
          <label className="w-40 text-black" title="block question">Blocked</label>
          <input className="border p-2" type="checkbox" id="Edit Question New Handed At" checked={edit_question.blocked_at !== null} onChange={(e) => set_edit_question((_prev) => ({ ..._prev, blocked_at: e.target.checked ? moment().toDate() : null }))} />
          {edit_question.blocked_at && <label className="grow shrink text-gray-700" htmlFor="Edit Question Block">Blocked AT {moment(edit_question.blocked_at).format('YYYY-MM-DD HH:mm:ss')}</label>}
        </div>
        <div className="flex justify-between items-center mt-2">
          <button className="px-3 py-1 border text-black" onClick={() => set_open_edit_question(false)}>Close</button>
          <button className="px-3 py-1 bg-green text-white" disabled={loading_questions} onClick={update_edit_question}>{loading_questions ? 'Updating' : 'Update'}</button>
        </div>
      </Modal >
    </div >
  )
}