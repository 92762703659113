import { CBCustomer, CBItemPrice } from "@prisma/client";
import { API_BACKEND_ENDPOINT } from "../config";
import axios, { AxiosError } from "axios";
import { CBItemPriceWithItem } from "@clc-v2/utilities";

class PaymentService {
    async uploadCBPaymentCSV(csv: File) {
        return new Promise<boolean>((resolve, reject) => {
            const formData = new FormData();
            formData.append("file", csv);
            axios.post(`${API_BACKEND_ENDPOINT}/cb-payment/upload`, formData, {
                timeout: 3600 * 1000
            }).then((res: { data: null }) => {
                resolve(true);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        })
    }
    async getCustomers() {
        return new Promise<Array<CBCustomer>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/cb-payment/customers`).then((res: { data: CBCustomer[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async getAllItemPrices() {
        return new Promise<Array<CBItemPriceWithItem>>((resolve, reject) => {
            axios.get(`${API_BACKEND_ENDPOINT}/cb-payment/all-item-prices`).then((res: { data: CBItemPriceWithItem[] }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async updatePriceHandler(id: string, updating_price: Omit<CBItemPrice, "item_id" | "item" | "created_at" | "updated_at">) {
        return new Promise<CBItemPrice>((resolve, reject) => {
            axios.patch(`${API_BACKEND_ENDPOINT}/cb-payment/item-prices/${id}`, updating_price).then((res: { data: CBItemPrice }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
    async syncAllSubcriptions() {
        return new Promise<{ success: number; failed: number; message: string }>((resolve, reject) => {
            axios.post(`${API_BACKEND_ENDPOINT}/cb-payment/sync-all-subscriptions`).then((res: { data: { success: number; failed: number; message: string } }) => {
                resolve(res.data);
            }).catch((err: AxiosError<{ message: string; error: string; statusCode: number; }>) => {
                if (err.response) {
                    reject(err.response.data.message);
                } else {
                    reject(err.message);
                }
            });
        });
    }
}

export const paymentService = new PaymentService();
