import { useEffect, useState } from "react";
import { topicLessonQuestionService } from "../services/topic-lesson-question.service";
import { Tabs } from "@clc-v2/uis";
import { TopicsPanel } from "../components/topic-lesson-question/topics-panel";
import { useAppDispatch } from "../stores/hooks";
import { set_glossaries, set_lessons, set_topics } from "../stores/topic-lesson-question";
import { useListener } from 'react-bus';
import { LessonsPanel } from "../components/topic-lesson-question/lessons-panel";
import { QuestionsPanel } from "../components/topic-lesson-question/questions-panel";
import { GlossariesPanel } from "../components/topic-lesson-question/glossaries-panel";

export const TopicLessonQuestion = () => {
    const dispatch = useAppDispatch();
    const [active_tab, set_active_tab] = useState<string>('topics');
    const get_all_topics = () => {
        topicLessonQuestionService.getAllTopics().then((_topics) => {
            dispatch(
                set_topics(_topics)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const get_all_lessons = () => {
        topicLessonQuestionService.getAllLessons().then((_lessons) => {
            dispatch(
                set_lessons(_lessons.sort((a, b) => {
                    if (a.topic_uid > b.topic_uid) {
                        return 1
                    } else if (a.topic_uid < b.topic_uid) {
                        return -1
                    } else {
                        if (a.order > b.order) {
                            return 1
                        } else {
                            return -1
                        }
                    }
                }))
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    const get_all_glossaries = () => {
        topicLessonQuestionService.getAllGlossaries().then((_glossaries) => {
            dispatch(
                set_glossaries(_glossaries)
            )
        }).catch((err_msg: string) => {
            console.error(err_msg);
        });
    }
    useListener('refetch-topics', get_all_topics);
    useListener('refetch-lessons', get_all_lessons);
    useListener('refetch-glossaries', get_all_glossaries);
    useEffect(() => {
        get_all_topics();
        get_all_lessons();
        get_all_glossaries();
    }, []);
    return (
        <div className="">
            <Tabs
                type={1}
                options={[
                    {
                        label: 'Topics', value: 'topics', description: 'Topics'
                    },
                    {
                        label: 'Lessons', value: 'lessons', description: 'Lessons'
                    },
                    {
                        label: 'Questions', value: 'questions', description: 'Questions'
                    },
                    {
                        label: 'Glossaries', value: 'glossaries', description: 'Glossaries'
                    }
                ]}
                activeValue={active_tab}
                onTabChangeHandler={(_new_value) => new Promise((resolve, reject) => { set_active_tab(_new_value); resolve(true); })}
            />
            {
                active_tab === 'topics' && (
                    <TopicsPanel />
                )
            }
            {
                active_tab === 'lessons' && (
                    <LessonsPanel />
                )
            }
            {
                active_tab === 'questions' && (
                    <QuestionsPanel />
                )
            }
            {
                active_tab === 'glossaries' && (
                    <GlossariesPanel />
                )
            }
        </div>
    )
}