import { ChangeEvent, useEffect, useState } from "react";
import { usersService } from "../services/users.service";
import moment from "moment";
import { UserItem } from "../components/users/user-item";
import { useLocation } from "react-router-dom";
import Modal from "react-responsive-modal";
import { IcCheck, IcClose1, IcLink, IcUpload } from "@clc-v2/icons";
import { ROLES, checkRole, IUserDetail, IUserWithAccountsAndInvite, IUserWithSubscription } from "@clc-v2/utilities";
import { app } from "../services/firebase";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { toast } from "react-toastify";
import { V1Earning, v1Service } from "../services/v1.service";
import { libraryService } from "../services/library.service";
import { historyService } from "../services/history.service";
import { debounce } from "lodash";
import { paymentService } from "../services/payment.service";

export const Users = () => {
    const { hash } = useLocation();
    const [loading_users, set_loading_users] = useState<boolean>(false);
    const [users, set_users] = useState<IUserWithAccountsAndInvite[]>([]);
    const [open_edit_modal, set_open_edit_modal] = useState<boolean>(false);
    const [loading_edit_user, set_loading_edit_user] = useState<boolean>(false);
    const [edit_user_id, set_edit_user_id] = useState<string>('');
    const [edit_user, set_edit_user] = useState<IUserDetail>();
    const [open_invites_modal, set_open_invites_modal] = useState<boolean>(false);
    const [loading_invited_users, set_loading_invited_users] = useState<boolean>(false);
    const [invited_users, set_invited_users] = useState<IUserWithSubscription[]>([]);
    const [loading_library_author, set_loading_library_author] = useState<boolean>(false);
    const [loading_block_user, set_loading_block_user] = useState<boolean>(false);
    const [loading_update_user, set_loading_update_user] = useState<boolean>(false);
    const [loading_update_users, set_loading_update_users] = useState<boolean>(false);
    const [firebase_signin_email, set_firebase_signin_email] = useState<string>('');
    const [v1_token, set_v1_token] = useState<string>('');
    const [search_word, set_search_word] = useState<string>('');
    const [syncing_subscriptions, set_syncing_subscriptions] = useState<boolean>(false);
    // const on_change_library_author_handler = () => {
    //     if (edit_user) {
    //         set_loading_library_author(true);
    //         libraryService.updateAuthor(edit_user.id).then(({ created, data }) => {
    //             if (created) {
    //                 set_edit_user((_prev) => _prev ? ({ ..._prev, library_author: data }) : undefined);
    //             } else {
    //                 set_edit_user((_prev) => _prev ? ({ ..._prev, library_author: undefined }) : undefined);
    //             }
    //         }).catch((err_msg: string) => {
    //             console.error(err_msg);
    //         }).finally(() => {
    //             set_loading_library_author(false);
    //         });
    //     }
    // };
    const on_block_user_handler = () => {
        if (edit_user) {
            set_loading_block_user(true);
            usersService.blockUser(edit_user.id).then((success) => {
                if (success) {
                    set_edit_user((_prev) => _prev ? ({ ..._prev, blocked_at: new Date() }) : undefined);
                    toast.success(`User (${edit_user.id}) is blocked`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).finally(() => {
                set_loading_block_user(false);
            });
        }
    };
    const on_unblock_user_handler = () => {
        if (edit_user) {
            set_loading_block_user(true);
            usersService.unblockUser(edit_user.id).then((success) => {
                if (success) {
                    set_edit_user((_prev) => _prev ? ({ ..._prev, blocked_at: null }) : undefined);
                    toast.success(`User (${edit_user.id}) is unblocked`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).finally(() => {
                set_loading_block_user(false);
            });
        }
    };
    const on_update_user_handler = () => {
        if (edit_user) {
            set_loading_update_user(true);
            usersService.updateUser(edit_user).then((updated) => {
                if (updated) {
                    set_open_edit_modal(false);
                    set_users((users) => users.map((user) => user.id === updated.id ? ({ ...user, ...updated }) : user));
                    toast.success(`User (${updated.id}) is updated`, {
                        position: "top-center",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).finally(() => {
                set_loading_update_user(false);
            })
        }
    };
    const on_sync_v1_user_handler = async (_edit_user: IUserDetail, successNotify: (str: string) => void, errorNotify: (str: string) => void) => {
        if (getAuth().currentUser && v1_token) {
            if (_edit_user?.id_v1_firebase) {
                // try {
                //     const earnings_doc_ref = doc(firestore, "earnings", _edit_user.id_v1_firebase);
                //     const earnings_doc_snap = await getDoc(earnings_doc_ref).catch((err) => {
                //         console.error(err)
                //     });
                //     if (earnings_doc_snap && earnings_doc_snap.exists()) {
                //         const earning_data = earnings_doc_snap.data() as IEarningData;
                //         await usersService.syncUserEarningData(earning_data, _edit_user.id);
                //         successNotify(`User (${_edit_user.id}) 's v1 firebase data is syncronized`);
                //     } else {
                //         errorNotify(`No such document ${_edit_user.id_v1_firebase}`)
                //     }
                // } catch (err_msg: any) {
                //     errorNotify(err_msg);
                // }
                // try {
                //     const users_doc_ref = doc(firestore, "users", _edit_user.id_v1_firebase);
                //     const users_doc_snap = await getDoc(users_doc_ref).catch((err) => {
                //         console.error(err)
                //     });
                //     if (users_doc_snap && users_doc_snap.exists()) {
                //         const user_data = users_doc_snap.data() as IUsersData;
                //         await usersService.syncUserData(user_data, _edit_user.id)
                //         successNotify(`Updated mastered lessons, topics of user ${_edit_user.id}`);
                //     } else {
                //         errorNotify(`No such document ${_edit_user.id_v1_firebase}`)
                //     }
                // } catch (err_msg: any) {
                //     errorNotify(err_msg);
                // }
                if (_edit_user.id_v1) {
                    let v1Data: {
                        referEmail: string;
                        earnings: V1Earning[];
                        watchedLibraries: string[];
                        couponCode: string;
                    } = {
                        referEmail: '',
                        earnings: [],
                        watchedLibraries: [],
                        couponCode: ''
                    }
                    try {
                        v1Data = await v1Service.getV1Data(_edit_user.id_v1, v1_token);
                    } catch (err_msg: any) {
                        errorNotify(err_msg);
                    }
                    try {
                        const coupon_result = await usersService.updateReferer(_edit_user.id, v1Data.referEmail);
                        successNotify(`${coupon_result.cb_coupon_id} is registered to user ${_edit_user.id}`);
                    } catch (err_msg: any) {
                        errorNotify(err_msg);
                    }
                    try {
                        const coupon_result = await usersService.updateCoupon(_edit_user.id, v1Data.couponCode);
                        successNotify(`${coupon_result.cb_coupon_id} is registered to user ${_edit_user.id}`);
                    } catch (err_msg: any) {
                        errorNotify(err_msg);
                    }
                    try {
                        const library_watched_result = await libraryService.updateLibraryVideoWatched(_edit_user.id, v1Data.watchedLibraries);
                        successNotify(`${library_watched_result.count} videos are registered to watched video of user ${_edit_user.id} newly`);
                    } catch (err_msg: any) {
                        errorNotify(err_msg);
                    }
                    try {
                        const earning_history_result = await historyService.updateEarningHistory(_edit_user.id, v1Data.earnings);
                        successNotify(`${earning_history_result.count} earning history are registered to user ${_edit_user.id} as v1 history`);
                    } catch (err_msg: any) {
                        errorNotify(err_msg);
                    }
                }
            } else {
                errorNotify("Seems user is not v1 user because user does not have firebase ID");
            }
        } else {
            errorNotify("Sign In to Firebase!");
        }
    }
    const on_sync_v1_users_handler = async () => {
        set_loading_update_users(true);
        for (const user of users) {
            await on_sync_v1_user_handler(user, (str) => toast.success(str, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            }), (str) => toast.warn(str, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            }))
        }
        set_loading_update_users(false);
    }
    const update_user = (user_id: string) => {
        set_loading_edit_user(true);
        usersService.getUserDetail(user_id).then((_user) => {
            set_edit_user(_user);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            set_loading_edit_user(false);
        })
    }
    const update_users = () => {
        set_loading_users(true);
        usersService.getAllUsers().then((_users) => {
            set_users([..._users]);
        }).catch((err_msg: string) => {
            console.error(err_msg);
        }).finally(() => {
            set_loading_users(false);
        });
    }
    const users_backup_v1_csv_upload_handler = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file: File = e.target.files[0];
            set_loading_users(true);
            usersService.uploadUsersCSV(file).then((success) => {
                update_users();
                toast.success(`Users csv file(v1) is uploaded successfully`, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).catch((err_msg: string) => {
                toast.warn(err_msg, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }).finally(() => {
                set_loading_users(false);
            });
        }
    }
    const firebase_login = () => {
        const email = prompt("What was your email address?");
        const password = prompt("What was your password?");
        if (email && password) {
            if (app) {
                const auth = getAuth();
                signInWithEmailAndPassword(auth, email, password).then((user_credential) => {
                    if (user_credential.user.email) {
                        set_firebase_signin_email(user_credential.user.email)
                        v1Service.getV1Token(email, password).then(({ token }) => {
                            set_v1_token(token);
                        }).catch((err_msg: string) => {
                            alert(err_msg);
                        });
                    }
                }).catch((error) => {
                    alert(error.message);
                })
            }
        } else {
            alert("Require email and password");
        }
    }
    const firebase_logout = () => {
        signOut(getAuth()).then(() => {
            set_firebase_signin_email('')
            set_v1_token('');
        });
    }
    const reset_chips = (user_id: string) => {
        usersService.resetChips(user_id).then(() => {
            update_user(user_id);
        }).catch((err_msg: string) => {
            alert(err_msg);
        });
    }
    const reset_all_users_chips = () => {
        usersService.resetAllUsersChips().then(() => {
            update_users();
        }).catch((err_msg: string) => {
            alert(err_msg);
        });
    }
    const sync_all_subscriptions = debounce(() => {
        set_syncing_subscriptions(true)
        paymentService.syncAllSubcriptions().then((res) => {
            alert(`Success: ${res.success}, Failed: ${res.failed}, Message: ${res.message}`);
        }).catch((err_msg: string) => {
            alert(err_msg);
        }).finally(() => {
            set_syncing_subscriptions(false);
        })
    }, 300);
    const get_invites_with_subscription = (user_id: string) => {
        set_loading_invited_users(true);
        usersService.getInvitesWithSubscription(user_id).then((res) => {
            set_invited_users(res);
        }).catch((err_msg: string) => {
            alert(err_msg);
        }).finally(() => {
            set_loading_invited_users(false);
        })
    };
    useEffect(() => {
        update_users();
    }, []);
    useEffect(() => {
        if (edit_user_id) {
            update_user(edit_user_id);
        }
    }, [edit_user_id]);
    useEffect(() => {
        setTimeout(() => {
            const auth = getAuth();
            if (auth.currentUser) {
                if (auth.currentUser?.email) {
                    set_firebase_signin_email(auth.currentUser?.email);
                }
                // signInWithEmailAndPassword(auth, "candy@withsocrates.com", "123456789").then((user_credential) => {
                //     console.log(user_credential.user)
                // }).catch((error) => {
                //     const errorCode = error.code;
                //     const errorMessage = error.message;
                //     console.error(errorCode, errorMessage)
                // }).finally(() => {
                //     console.log('Final')
                //     getDocs(collection(firestore, "earnings")).then((earnings_query_snapshot) => {
                //         earnings_query_snapshot.forEach((doc) => {
                //         })
                //     }).catch((error) => {
                //         console.error(error)
                //     })
                // })
            }
        }, 5000)
    }, []);
    return (
        <div className="w-full">
            <div className="flex justify-between items-center">
                <div className="flex items-center gap-4">
                    <span className="text-white">Users</span>
                    <input type="text" placeholder="Search" value={search_word} onChange={(e) => set_search_word(e.target.value)} />
                </div>
                <div className="flex items-center gap-4">
                    <span className="text-white">{firebase_signin_email}</span>
                    <label title="Upload CSV of Users Data from backup v1" className="rounded-full w-5 h-5 flex justify-center items-center cursor-pointer" htmlFor="library-users-csv-file-upload">
                        <IcUpload />
                    </label>
                    <input id="library-users-csv-file-upload" hidden type="file" multiple={false} accept=".csv" onChange={users_backup_v1_csv_upload_handler} />
                    {(firebase_signin_email === '' && v1_token === '') ? (
                        <button className="bg-gray-200 px-2 rounded-sm" onClick={firebase_login}>Sign In With V1 Credential</button>
                    ) : (
                        <>
                            <button className="bg-gray-200 px-2 rounded-sm" onClick={firebase_logout}>Sign Out</button>
                            <button disabled={loading_update_users} className="bg-gray-200 px-2 rounded-sm" onClick={on_sync_v1_users_handler}>{loading_update_users ? "Updating" : "Sync all from firestore"}</button>
                        </>
                    )}
                    <button className="bg-gray-200 px-2 rounded-sm" onClick={reset_all_users_chips}>Reset Chips for all users</button>
                    <button className="bg-gray-200 px-2 rounded-sm" disabled={syncing_subscriptions} onClick={sync_all_subscriptions}>Sync All Subscriptions</button>
                </div>
            </div>
            <div className="w-full grid grid-cols-12 gap-1 border-b border-white border-solid">
                <div>
                    <span className="text-white">ID</span>
                </div>
                <div>
                    <span className="text-white">V1 Data</span>
                </div>
                <div>
                    <span className="text-white">Email</span>
                </div>
                <div>
                    <span className="text-white">Picture</span>
                </div>
                <div>
                    <span className="text-white">Role</span>
                </div>
                <div>
                    <span className="text-white">Accounts</span>
                </div>
                <div>
                    <span className="text-white">Invited BY</span>
                </div>
                <div>
                    <span className="text-white">Last Login At</span>
                </div>
                <div>
                    <span className="text-white">Created At</span>
                </div>
                <div>
                    <span className="text-white">Updated At</span>
                </div>
                <div>
                    <span className="text-white">Blocked At</span>
                </div>
                <div className="overflow-auto">
                    <span className="text-white">Activity</span>
                </div>
            </div>
            {!loading_users ? (users.length > 0 ? (
                users.filter((user) => (
                    user.id.includes(search_word) ||
                    user.id_v1?.includes(search_word) ||
                    user.email.toLowerCase().includes(search_word.toLowerCase()) ||
                    user.picture.includes(search_word) ||
                    user.accounts.map(({ type }) => type).includes(search_word) ||
                    user.invited?.id.includes(search_word)
                )).map((user) => (
                    <UserItem
                        key={user.id}
                        user={user}
                        active={hash === `#${user.id}`}
                        onEditHandler={() => {
                            set_edit_user_id(user.id);
                            set_open_edit_modal(true);
                        }}
                        onInformationHandler={() => {
                            set_open_invites_modal(true);
                            get_invites_with_subscription(user.id);
                        }}
                    />
                ))
            ) : (
                <span className="text-white">NO Users</span>
            )) : (
                <span className="text-white">Loading Users</span>
            )}
            <Modal open={open_edit_modal} onClose={() => set_open_edit_modal(false)}>
                <h2 className="font-semibold">Edit User</h2>
                {loading_edit_user ? (<div>Loading Detail</div>) : (
                    edit_user ? (
                        <>
                            <div className="flex items-start gap-2">
                                <label className="w-40" htmlFor="Edit User ID">ID</label>
                                <p id="Edit User ID">{edit_user.id}</p>
                            </div>
                            <div className="flex items-start gap-2">
                                <label className="w-40" htmlFor="Edit User ID V1">ID V1</label>
                                <p id="Edit User ID V1">{edit_user.id_v1}</p>
                            </div>
                            <div className="flex items-start gap-2">
                                <label className="w-40" htmlFor="Edit User ID V1 Firebase">ID V1(Firebase)</label>
                                <p id="Edit User ID V1 Firebase">{edit_user.id_v1_firebase}</p>
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Given Name">Given name</label>
                                <input
                                    id="Edit User Given Name"
                                    className="grow shrink border"
                                    type="text"
                                    value={edit_user.given_name ?? ''}
                                    onChange={(e) => {
                                        set_edit_user((_prev) => _prev ? ({ ..._prev, given_name: e.target.value }) : undefined);
                                    }}
                                />
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Family Name">Family name</label>
                                <input
                                    id="Edit User Family Name"
                                    className="grow shrink border"
                                    type="text"
                                    value={edit_user.family_name ?? ''}
                                    onChange={(e) => {
                                        set_edit_user((_prev) => _prev ? ({ ..._prev, family_name: e.target.value }) : undefined);
                                    }}
                                />
                            </div>
                            <div className="flex items-center gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Email">Email</label>
                                <input
                                    id="Edit User Email"
                                    className="grow shrink border"
                                    type="email"
                                    readOnly
                                    value={edit_user.email}
                                    onChange={(e) => {
                                        set_edit_user((_prev) => _prev ? ({ ..._prev, email: e.target.value }) : undefined);
                                    }}
                                />
                                {edit_user.email_verified ? <IcCheck /> : <IcClose1 size={16} />}
                            </div>
                            <div className="flex items-center gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Picture">Picture</label>
                                <input
                                    id="Edit User Picture"
                                    className="grow shrink border"
                                    type="text"
                                    value={edit_user.picture}
                                    onChange={(e) => {
                                        set_edit_user((_prev) => _prev ? ({ ..._prev, picture: e.target.value }) : undefined);
                                    }}
                                />
                                {edit_user.picture ? <span className="bg-black cursor-pointer" onClick={() => { window.open(edit_user.picture, '_blank') }}>
                                    <IcLink />
                                </span> : null}
                            </div>
                            <div className="flex items-center gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Role">Role</label>
                                <select className="shrink grow border" name="" id="Edit User Role" multiple onChange={(e) => {
                                    const roles = Object.values(e.target.selectedOptions).map((option) => parseInt(option.value));
                                    set_edit_user((_prev) => _prev ? ({ ..._prev, role: roles.reduce((accumulator, current_value) => accumulator | current_value) }) : undefined);
                                }}>
                                    {Object.entries(ROLES).map((role_key) => (
                                        <option selected={checkRole(role_key[1], edit_user.role)} key={role_key[1]} value={role_key[1]}>{role_key[0]}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User UTM">UTM</label>
                                <div className="shrink grow grid grid-cols-2 gap-2">
                                    <label htmlFor="Edit User UTM Source">Source</label>
                                    <label htmlFor="Edit User UTM Medium">Medium</label>
                                    <p id="Edit User UTM Source">{edit_user.utm_source}</p>
                                    <p id="Edit User UTM Medium">{edit_user.utm_medium}</p>
                                    <label htmlFor="Edit User UTM Campaign">Campaign</label>
                                    <label htmlFor="Edit User UTM Content">Content</label>
                                    <p id="Edit User UTM Campaign">{edit_user.utm_campaign}</p>
                                    <p id="Edit User UTM Content">{edit_user.utm_content}</p>
                                    <label htmlFor="Edit User UTM Term">Term</label>
                                    <div></div>
                                    <p id="Edit User UTM Term">{edit_user.utm_term}</p>
                                    <div></div>
                                </div>
                            </div>
                            <div className="flex items-center gap-2 mt-2">
                                <p
                                    id="Edit User Last Checked New Hands"
                                    className="grow shrink border"
                                >{edit_user.last_checked_at_new_hands ? moment(edit_user.last_checked_at_new_hands).format('YYYY-MM-DD HH:mm:ss') : 'Not Set'}</p>
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Accounts">Accounts</label>
                                <div className="flex flex-col gap-1">
                                    {edit_user.accounts.length > 0 ? edit_user.accounts.map((account, id) => (
                                        <div key={id}>{account.provider}</div>
                                    )) : (
                                        <span>No Connected Accounts</span>
                                    )}
                                </div>
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Invited By">Invite</label>
                                {edit_user.invited?.referer_id ? (
                                    <p
                                        id="Edit User Invited By"
                                        className="grow shrink border"
                                    >{edit_user.invited.referer_id}</p>
                                ) : (
                                    <span>No Invited</span>
                                )}
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Lifetime Point">Lifetime Point</label>
                                <div className="grow shrink flex flex-col gap-1">
                                    <p
                                        id="Edit User Lifetime Point Correct"
                                        className="border"
                                    >Correct Answers: {edit_user.lifetime_point?.correct ?? 0}</p>
                                    <p
                                        id="Edit User Lifetime Point Earning Chips"
                                        className="border"
                                    >Total Answers: {edit_user.lifetime_point?.total ?? 0}</p>
                                    <p
                                        id="Edit User Lifetime Point Earning Chips"
                                        className="border"
                                    >Earning Chips: {edit_user.lifetime_point?.chips ?? 0}</p>
                                    <p
                                        id="Edit User Lifetime Point Earning Chips"
                                        className="border"
                                    >Earning Tickets: {edit_user.lifetime_point?.earned_tickets ?? 0}</p>
                                </div>
                            </div>
                            <div className="grid grid-cols-2 gap-4">
                                <label className="" htmlFor="Edit User Last Claim Daily Reward">Last Claim Daily Reward</label>
                                <label className="" htmlFor="Edit User Last Claim Weekly New Hands">Last Claim Weekly New Hands</label>
                                <p
                                    id="Edit User Last Claim Daily Reward"
                                    className="border"
                                >{edit_user.user_last_claimed_daily_20_questions ?? 'Not Set'}</p>

                                <p
                                    id="Edit User Last Claim Weekly New Hands"
                                    className="border"
                                >{edit_user.user_last_claimed_weekly_new_hands ?? 'Not Set'}</p>
                            </div>
                            <div className="flex items-start gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Lifetime Point">Coupon</label>
                                <div className="grow shrink flex flex-col gap-1">
                                    <p
                                        id="Edit User Coupon"
                                        className="border"
                                    >{edit_user.cb_coupon?.name}</p>
                                </div>
                            </div>
                            {/* <div className="flex items-center gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User Library Author" title="Add User to Author Immediately">Library Author</label>
                                <input type="checkbox" checked={!!edit_user.library_author} onChange={on_change_library_author_handler} />
                                {loading_library_author && (<span>Loading</span>)}
                            </div> */}
                            <div className="flex items-center gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User CreatedAt">Created At</label>
                                <p className="shrink grow border-collapse" >{moment(edit_user.created_at).format('YYYY-MM-DD HH:mm:ss')}</p>
                            </div>
                            <div className="flex items-center gap-2 mt-2">
                                <label className="w-40" htmlFor="Edit User BlockedAt" title="Block User Immediately">Block At</label>
                                <p className="shrink grow border-collapse" >{edit_user.blocked_at ? moment(edit_user.blocked_at).format('YYYY-MM-DD HH:mm:ss') : 'Active'} {loading_block_user && 'Loading'}</p>
                                {edit_user.blocked_at ? (
                                    <button className="border px-3 py-1 bg-green text-white" disabled={loading_block_user} onClick={on_unblock_user_handler}>Unblock</button>
                                ) : (
                                    <button className="border px-3 py-1 bg-red-500 text-yellow-500" disabled={loading_block_user} onClick={on_block_user_handler}>Block</button>
                                )}
                            </div>
                            <div className="flex justify-between items-center mt-2">
                                <button className="px-3 py-1 bg-white border text-black" onClick={() => set_open_edit_modal(false)}>Close</button>
                                <div className="flex items-center gap-2">
                                    {(firebase_signin_email && v1_token) ? <button
                                        className="px-3 py-1 bg-green border text-white"
                                        disabled={loading_update_user}
                                        onClick={async () => {
                                            set_loading_update_user(true);
                                            await on_sync_v1_user_handler(edit_user, (str) => toast.success(str, {
                                                position: "top-center",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "dark",
                                            }), (str) => toast.warn(str, {
                                                position: "top-center",
                                                autoClose: 5000,
                                                hideProgressBar: false,
                                                closeOnClick: true,
                                                pauseOnHover: true,
                                                draggable: true,
                                                progress: undefined,
                                                theme: "dark",
                                            }));
                                            set_loading_update_user(false);
                                        }}
                                    >{loading_update_user ? 'Updating' : 'Sync to V1'}</button> : <span>Loading</span>}
                                    <button
                                        className="px-3 py-1 bg-green border text-white"
                                        disabled={loading_update_user}
                                        onClick={on_update_user_handler}
                                    >{loading_update_user ? 'Updating' : 'Update'}</button>
                                    <button className="px-3 py-1 bg-green border text-white" onClick={() => reset_chips(edit_user.id)}>Reset Chips</button>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>
                            Not Found User
                        </p>
                    )
                )}
            </Modal>
            <Modal open={open_invites_modal} onClose={() => set_open_invites_modal(false)}>
                <h2 className="font-semibold">Invites</h2>
                {loading_invited_users ? (<div>Loading Invites</div>) : (
                    <div className="w-full flex flex-col gap-4 mt-6">
                        <div className="w-full grid grid-cols-6 items-center gap-4">
                            <span>ID</span>
                            <span>Email</span>
                            <span>Joined At</span>
                            <div>Payment</div>
                        </div>
                        {invited_users.map(({ invited: user }) => (
                            <div className="w-full grid grid-cols-6 items-center gap-4">
                                <span>{user.id}</span>
                                <span className="break-all">{user.email}</span>
                                <span>{moment(user.created_at).format('YYYY-MM-DD')}</span>
                                {user.cb_customer ? (
                                    <div className="col-span-3 flex flex-col gap-2">
                                        {user.cb_customer.subscription ? (
                                            <>
                                                <span>Plan: {user.cb_customer.subscription.billing_period} {user.cb_customer.subscription.billing_period_unit}</span>
                                                <span>Status: {user.cb_customer.subscription.status}</span>
                                                <span>Next Payment: {moment.unix(user.cb_customer.subscription.next_billing_at).format('YYYY-MM-DD HH:mm')}</span>
                                            </>
                                        ) : (
                                            <>
                                                <span>CustomerId: {user.cb_customer.id}</span>
                                                <span>Subscription: No</span>
                                            </>
                                        )}
                                    </div>
                                ) : <span>No Payment</span>}
                            </div>
                        ))}
                    </div>
                )}
            </Modal>
        </div >
    )
}